import BaseService from '@/service/BaseService'
import { protectedRequest } from '@/boot/axios'

class ReferenceExchangeRateService extends BaseService {
  constructor() {
    super('reference-exchange-rates')
  }

  effectiveRates() {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/effective-rates`,
    })
  }
}

export default new ReferenceExchangeRateService()
