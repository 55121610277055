import { initAccount, setCompanies, setUsers } from './types'

export default {
  [initAccount.requesting](state) {
    state.isInit = true
  },
  [initAccount.failure](state, { payload, rootState }) {
    rootState.message = { text: payload.message, type: 'error' }
    state.isInit = false
  },
  [initAccount.success](state, { payload, rootState }) {
    rootState.message = { text: payload.message, type: 'success' }
    state.isInit = false
  },
  [setUsers](state, { items }) {
    state.users = items
  },
  [setCompanies](state, { items }) {
    state.companies = items
  },
}
