import { useToast } from 'primevue/usetoast'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default function useConnectorMapping(resource, callbacks) {
  const toast = useToast()
  const store = useStore()
  const displayModal = ref(false)

  const connector = computed(() => {
    return store.getters['connector/currentConnector']
  })

  const currentCompany = computed(() => {
    return store.getters['auth/currentCompany']
  })

  // Call it as soon as possible before using other features
  const loadConnector = async () => {
    await store.dispatch('connector/getByCompany', currentCompany.value?._id)
  }

  const mapping = computed(() => {
    if (connector.value) {
      return connector.value.mapping.reduce((acc, curr) => {
        const { field, softwareName, displayName } = curr
        acc[field] = {
          softwareName,
          displayName,
        }

        return acc
      }, {})
    }

    return null
  })

  const getFieldDisplayName = (field) => {
    if (!mapping.value) {
      return ''
    }

    return mapping.value[field]
      ? mapping.value[field].displayName || mapping.value[field].softwareName
      : ''
  }

  const openModal = () => {
    displayModal.value = true
  }

  const closeModal = () => {
    displayModal.value = false
  }

  const importFromSoftwareFile = async (event) => {
    try {
      const file = event.files[0]
      const formData = new FormData()
      formData.append('company', currentCompany.value.code)
      formData.append('csv', file)
      const data = await store.dispatch(`localClient/importWithFileConnector`, {
        connector: connector.value.type,
        formData,
      })
      await callbacks.fetchData()
      await callbacks.openReport(data)
      toast.add({
        severity: 'success',
        summary: 'Import Success',
        detail: `Local Client import succeeded`,
        life: 2000,
      })

      return data
    } catch (e) {
      toast.add({
        severity: 'error',
        summary: 'Import Failure',
        detail: `Local Client import failed !`,
        life: 2000,
      })
    }
  }

  const importFromSoftwareApi = async () => {
    try {
      const data = await store.dispatch(`${resource}/syncUsingApi`, {
        softwareId: connector.value._id,
        company: currentCompany.value.code,
      })
      await callbacks.fetchData()
      await callbacks.openReport(data)
      toast.add({
        severity: 'success',
        summary: 'Import Success',
        detail: `Import succeeded`,
        life: 2000,
      })

      return data
    } catch (e) {
      toast.add({
        severity: 'error',
        summary: 'Import Failure',
        detail: `Import failed !`,
        life: 2000,
      })
    }
  }

  return {
    displayModal,
    connector,
    mapping,
    getFieldDisplayName,
    openModal,
    closeModal,
    loadConnector,
    importFromSoftwareFile,
    importFromSoftwareApi,
  }
}
