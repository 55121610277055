<template>
  <span v-if="hasError && isDirty">
    <span v-for="(error, index) of errors" :key="index">
      <small class="p-error">{{
        error.$message.replace('Value', fieldName)
      }}</small>
    </span>
  </span>
  <small v-else-if="(isInvalid && isDirty) || isPending" class="p-error">{{
    requiredMessage
  }}</small>
</template>

<script>
import { get } from 'lodash-es'

export default {
  props: {
    validator: {
      type: Object,
      required: true,
    },

    field: {
      type: String,
      required: true,
    },

    fieldTitle: {
      type: String,
      default: '',
    },
  },

  computed: {
    validatorField() {
      return get(this.validator, this.field)
    },

    hasError() {
      return this.validatorField?.$error
    },

    isDirty() {
      return this.validatorField?.$dirty
    },

    isInvalid() {
      return this.validatorField?.$invalid
    },

    isPending() {
      return this.validatorField?.$pending.$response
    },

    errors() {
      return this.validatorField?.$errors
    },

    fieldName() {
      return this.fieldTitle || this.field
    },

    requiredMessage() {
      return this.validatorField?.required?.replace('Value', this.fieldName)
    },
  },
}
</script>

<style></style>
