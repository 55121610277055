import { defaultState } from './state'
import {
  authLogin,
  googleAuth,
  logout,
  refreshToken,
  removeMessage,
  setCurrentCompany,
  setIsForcedLogout,
  updateAuthData,
} from './types'
import { accountStatus } from '@/common/constants/status.constant'

export default {
  [authLogin.requesting](state) {
    state.isLoggingIn = true
  },
  [authLogin.failure](state, { payload, rootState }) {
    state.isLoggedIn = false
    state.isLoggingIn = false
    rootState.message = { text: payload.message, type: 'error' }
  },
  [authLogin.success](state, { payload, rootState }) {
    state.data = payload.data
    state.currentCompany =
      payload.data.user.selectedCompany || payload.data.user.companies[0]
    rootState.message = { text: payload.message, type: 'success' }
    state.isLoggingIn = false
    state.isLoggedIn = true
  },
  [refreshToken.requesting](state) {
    state.isLoggingIn = true
  },
  [refreshToken.failure](state, { payload, rootState }) {
    state.isLoggingIn = false
    rootState.message = { text: payload.message, type: 'error' }
  },
  [refreshToken.success](state, { payload, rootState }) {
    state.data = payload.data
    rootState.message = { text: payload.message, type: 'success' }
    state.isLoggingIn = false
    state.isLoggedIn = true
  },
  [googleAuth.requesting](state) {
    state.isLoggingIn = true
  },
  [googleAuth.failure](state, { payload, rootState }) {
    state.isLoggingIn = false
    rootState.message = { text: payload.message, type: 'error' }
  },
  [googleAuth.success](state, { payload, rootState }) {
    if (payload.data.user.account_status === accountStatus.VALIDATED) {
      // log in the user
      state.isLoggedIn = true
      state.data = payload.data
      state.currentCompany =
        payload.data.user.selectedCompany || payload.data.user.companies[0]
      state.redirect = {
        name: 'dashboard',
        params: { idCompany: state.currentCompany._id },
      }
    } else if (payload.data.user.account_status === accountStatus.CREATED) {
      state.redirect = '/account/initialization?token=' + payload.data.token
    } else {
      state.redirect = '/account/pending-approval'
    }
    rootState.message = { text: payload.message, type: 'success' }
    state.isLoggingIn = false
  },
  [updateAuthData](state, { authData, user, currentCompany }) {
    if (!authData) {
      state = { ...defaultState }
    }

    if (authData) state.data = authData

    if (user) state.data.user = user
    state.currentCompany = currentCompany || authData.user.companies[0]
    state.isLoggedIn = true
  },
  [removeMessage](state) {
    state.message = null
  },
  [logout](state) {
    state.data = null
    state.message = null
    state.isLoggedIn = false
    state.currentCompany = null
  },
  [setIsForcedLogout](state, payload) {
    state.isForcedLogout = payload
  },
  [setCurrentCompany](state, payload) {
    state.currentCompany = payload
  },
}
