import {
  baseProjectStateOptions,
  baseProjectStatesValues,
} from '@/common/constants/baseProject.constant'

export const accountStatus = {
  CREATED: 'Created',
  PENDING_VALIDATION: 'Pending Validation',
  VALIDATED: 'Validated',
}

export const localClientStatus = {
  PROSPECT: 'Prospect',
  CLIENT: 'Client',
  PARTNER: 'Partner',
  ARCHIVED: 'Archived',
}

export const contactStatus = {
  PROSPECT: 'Prospect',
  CLIENT: 'Client',
  PARTNER: 'Partner',
  SUPPLIER: 'Supplier',
  EMPLOYEE: 'Employee',
}

export const contactStatuses = Object.values(contactStatus)

export const localClientStatuses = Object.values(localClientStatus)

export const localClientStatusOptions = [
  {
    name: 'Prospect',
    color: 'warning',
  },
  {
    name: 'Client',
    color: 'success',
  },
  {
    name: 'Partner',
    color: 'info',
  },
  {
    name: 'Archived',
    color: 'error',
  },
]

export const contactStatusOptions = [
  {
    name: contactStatus.PROSPECT,
    color: 'warning',
  },
  {
    name: contactStatus.CLIENT,
    color: 'success',
  },
  {
    name: contactStatus.PARTNER,
    color: 'info',
  },
  {
    name: contactStatus.SUPPLIER,
    color: 'primary',
  },
  {
    name: contactStatus.EMPLOYEE,
    color: 'error',
  },
]

export const simpleProjectStateOptions = baseProjectStateOptions

export const internalProjectStateOptions = baseProjectStateOptions

export const internalProjectStateValues = baseProjectStatesValues

export const programManagementLocalStateValues = baseProjectStatesValues

export const programManagementLocalStateOptions = baseProjectStateOptions

export const productionBillingStatuses = {
  DRAFT_INVOICE: 'draft_invoice',
  ALREADY_INVOICED: 'already_invoiced',
  TO_BE_INVOICED: 'to_be_invoiced',
  CANCELLED: 'cancelled',
  ARCHIVED: 'archived',
}

export const productionPaymentStatuses = {
  PENDING_PAYMENT: 'pending_payment',
  PAID: 'paid',
  OVERDUE: 'overdue',
}

export const budgetStatus = {
  OPEN: 'Open',
  CLOSE: 'Close',
}

export const budgetStatuses = Object.values(budgetStatus)

export const loggingActions = [
  {
    label: 'Create',
    value: 'Create',
  },
  {
    label: 'Update',
    value: 'Update',
  },
  {
    label: 'Delete',
    value: 'Delete',
  },
]

export const featureStatus = {
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
}

export const scriptRunnerStatus = {
  PENDING: 'Pending',
  RUNNING: 'Running',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
}
