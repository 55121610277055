export const SET_LIST = (state, { items, count }) => {
  state.list = [...items]
  state.totalRecords = count
}

export const ADD_TO_LIST = (state, item) => {
  state.list = [item, ...state.list]
  state.totalRecords = state.totalRecords + 1
}

export const UPDATE_LIST = (state, item) => {
  const updateIndex = state.list.findIndex((el) => el._id === item._id)
  state.list[updateIndex] = item
  state.list = [...state.list]
}

export const REMOVE_FROM_LIST = (state, id) => {
  state.list = state.list.filter((el) => el._id !== id)
}

export const setAll = (state, { items }) => {
  state.all = items
}
