<template>
  <div class="pages-body login-page flex flex-column">
    <div
      class="topbar p-3 flex justify-content-between flex-row align-items-center"
    >
      <div class="topbar-left ml-3 flex">
        <div class="logo">
          <img alt="" src="images/logo.svg" />
        </div>
      </div>
    </div>

    <div class="align-self-center mt-auto mb-auto" style="width: 450px">
      <ProgressBar
        mode="indeterminate"
        style="height: 0.25em; position: absolute; top: 0; left: 0; right: 0"
      />
      <div class="card">
        <div class="flex justify-content-center">
          <img alt="" height="75" src="images/logo-labelium.svg" />
        </div>
        <h4 class="text-center">Welcome {{ formData.first_name }}</h4>

        <div class="pages-detail mb-5 px-6 text-center">
          To complete your profile, please fill in the following form.
        </div>

        <Message
          v-if="message"
          :severity="message.type"
          @close="clearAuthMessage"
          >{{ message.text }}
        </Message>

        <div class="p-fluid">
          <div class="field">
            <label class="text-left" for="first_name">First Name*</label>
            <InputText
              id="first_name"
              v-model="formData.first_name"
              :class="{
                'p-invalid': v$.first_name.$invalid && v$.first_name.$dirty,
              }"
              type="text"
            />

            <validation-error
              :validator="v$"
              field="first_name"
              field-title="First Name"
            />
          </div>

          <div class="field">
            <label for="last_name">Last Name*</label>
            <InputText
              id="last_name"
              v-model="formData.last_name"
              :class="{
                'p-invalid': v$.last_name.$invalid && v$.last_name.$dirty,
              }"
              type="text"
            />

            <validation-error
              :validator="v$"
              field="last_name"
              field-title="Last Name"
            />
          </div>
          <div class="field">
            <label for="companies">Companies*</label>
            <MultiSelect
              id="companies"
              v-model="formData.companies"
              v-filter-close-icon
              :class="{
                'p-invalid': v$.companies.$invalid && v$.companies.$dirty,
              }"
              :filter="true"
              :options="companyList"
              data-key="_id"
              display="chip"
              option-label="name"
              option-value="_id"
              placeholder="Select Companies"
            />
            <validation-error
              :validator="v$"
              field="companies"
              field-title="Companies"
            />
          </div>
          <div class="field">
            <label for="hierarchical_superiors">Hierarchical Superiors</label>
            <MultiSelect
              id="hierarchical_superiors"
              v-model="formData.hierarchical_superiors"
              v-filter-close-icon
              :filter="true"
              :options="hierarchyOptions"
              data-key="_id"
              display="chip"
              option-group-children="items"
              option-group-label="label"
              option-label="fullname"
              option-value="_id"
              placeholder="Select hierarchical superiors"
            >
              <template #optiongroup="slotProps">
                <div
                  v-if="slotProps.option.items.length"
                  class="flex align-items-center"
                >
                  <div class="font-bold">{{ slotProps.option.label }}</div>
                </div>
              </template>
              <template #option="slotProps">
                <span>{{ slotProps.option.fullname }}</span>
              </template>
            </MultiSelect>
          </div>
        </div>

        <Button
          :loading="isUpdatingUser"
          class="login-button px-6 block p-button mx-auto mt-5 mb-3"
          label="VALIDATE"
          @click="validate"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
import { computed, onBeforeMount, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ValidationError from '@/components/ui/ValidationError.vue'

const companyScope = {
  SAME_COMPANY: 'same_company',
  NO_COMPANY: 'no_company',
}

export default {
  components: {
    ValidationError,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const formData = reactive({
      _id: '',
      first_name: '',
      last_name: '',
      companies: [],
      hierarchical_superiors: [],
      token: '',
    })

    const fetchCurrentUser = async () => {
      try {
        if (!route.query.token) {
          router.push({ name: 'login' })

          return
        }
        const me = await store.dispatch('account/getMe', route.query.token)
        formData._id = me._id
        formData.first_name = me.first_name
        formData.last_name = me.last_name
        formData.token = route.query.token
      } catch (e) {
        await router.push({ name: 'login' })
      }
    }

    onBeforeMount(async () => {
      await fetchCurrentUser()
    })

    const message = computed(() => {
      return store.getters['message']
    })

    const clearAuthMessage = () => {
      store.dispatch('clearMessage')
    }

    const isUpdatingUser = computed(() => {
      return store.getters['account/isInit']
    })

    const rules = {
      first_name: { required, $autoDirty: true },
      last_name: { required, $autoDirty: true },
      companies: { required, $autoDirty: true },
    }

    const v$ = useVuelidate(rules, formData)

    return { formData, v$, isUpdatingUser, message, clearAuthMessage }
  },

  data() {
    return {
      hierarchyOptions: [],
    }
  },

  computed: {
    hierarchicalSuperiors() {
      return this.formData.hierarchical_superiors
    },

    userCompanies() {
      return this.formData.companies
    },

    companyList() {
      return this.$store.getters['account/companies']
    },
  },

  watch: {
    userCompanies: {
      async handler(value) {
        await this.fetchUserByCompany(
          value,
          this.formData._id,
          this.$route.query.token,
        )
      },

      deep: true,
    },
  },

  async created() {
    await this.$store.dispatch('account/getCompanies', this.$route.query.token)
  },

  async mounted() {
    if (this.formData._id) {
      await this.fetchUserByCompany(
        this.userCompanies,
        this.formData._id,
        this.$route.query.token,
      )
    } else {
      await this.fetchUserByCompany(
        this.userCompanies,
        null,
        this.$route.query.token,
      )
    }
  },

  methods: {
    async fetchUserByCompany(companies, userId = null, token = null) {
      const result = await this.$store.dispatch('account/getUserByCompany', {
        companies,
        userId,
        token,
      })
      this.hierarchyOptions = Object.keys(result).map((key) => {
        return {
          label:
            key === companyScope.SAME_COMPANY ? 'Same company' : 'No company',
          items: result[key],
        }
      })
    },

    async validate() {
      const formValid = await this.v$.$validate()

      if (!formValid) return

      try {
        await this.$store.dispatch('account/initialization', this.formData)
        this.$router.push({ name: 'pending_approval' })
      } catch (e) {
        alert(e)
      }
    },
  },
}
</script>

<style></style>
