import ServicesService from '../../../service/ServicesService'

export const getAll = async ({ commit }) => {
  const { data } = await ServicesService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await ServicesService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}
