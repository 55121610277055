import { protectedRequest } from '../boot/axios'

class AccountService {
  constructor() {
    this._apiUrl = '/account'
  }

  initialization(data, token) {
    return protectedRequest(
      {
        method: 'PUT',
        url: `${this._apiUrl}/initialization`,
        data,
      },
      token,
    )
  }

  getCompanies(token) {
    return protectedRequest(
      {
        method: 'GET',
        url: `/initialisation/companies`,
      },
      token,
    )
  }

  getUserByCompany(companies = [], userId = null, token = null) {
    const params = {
      q: companies.join(','),
    }

    if (userId) {
      params.user_id = userId
    }

    return protectedRequest(
      {
        method: 'GET',
        url: '/initialisation/user/company',
        params,
      },
      token,
    )
    // return this._axios.get(`${this._apiUrl}/company`, { params });
  }

  getMe(token) {
    return protectedRequest(
      {
        method: 'GET',
        url: '/initialisation/user/me',
      },
      token,
    )
  }
}

export default new AccountService()
