import ProgramManagementService from '../../../service/ProgramManagementService'
import * as mutationTypes from '@/store/modules/programManagement/types'

export const getAll = async ({ commit }) => {
  const { data } = await ProgramManagementService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await ProgramManagementService.paginatedSearch(query)

  for (let pm of data.items) {
    if (
      pm.intercoServices &&
      pm.intercoServices[query.filters.company.value[0]]
    ) {
      if (pm.intercoServices[query.filters.company.value[0]].status) {
        pm.localStatus = 'Validate'
      } else {
        pm.localStatus = 'Favorite local product missing'
      }
    } else if (pm.admin._id === query.filters.company.value[0]) {
      pm.localStatus = 'You are Admin'
    }
  }
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await ProgramManagementService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const getItemById = async (contect, id) => {
  const { data } = await ProgramManagementService.getById(id)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await ProgramManagementService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await ProgramManagementService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const saveFeesAsDraft = async (context, { id, payload }) => {
  const { data } = await ProgramManagementService.saveFeesAsDraft(id, payload)

  return data
}

export const generateGlobalRepartition = async (context, { id, payload }) => {
  const { data } = await ProgramManagementService.generateGlobalRepartition(
    id,
    payload,
  )

  return data
}

export const submitFeesToPartner = async (context, { id, payload }) => {
  const { data } = await ProgramManagementService.submitFeesToPartner(
    id,
    payload,
  )

  return data
}

export const submitProducerValidation = async (context, { id, payload }) => {
  const { data } = await ProgramManagementService.submitProducerValidation(
    id,
    payload,
  )

  return data
}

export const submitToAdminBiller = async (context, { id, payload }) => {
  const { data } = await ProgramManagementService.submitToAdminBiller(
    id,
    payload,
  )

  return data
}

export const duplicateItem = async ({ commit }, id) => {
  const { data } = await ProgramManagementService.duplicate(id)
  commit('ADD_TO_LIST', data)

  return data
}

export const countPerState = async ({ commit }, payload) => {
  const { data } = await ProgramManagementService.countPerState(payload)
  commit('DATA_COUNTED_PER_STATE', data)

  return data
}

export const exportCsv = async ({ commit, rootState }, { filters }) => {
  try {
    commit(mutationTypes.exportCsv.requesting)
    const res = await ProgramManagementService.exportCsv(filters)
    commit(mutationTypes.exportCsv.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportCsv.failure, { payload: e, rootState })
  }
}
