import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class ClientService extends BaseService {
  constructor() {
    super('clients')
  }

  // other implementation goes here
  getByType(type, params = {}) {
    return protectedRequest({
      method: 'GET',
      url: this._apiUrl + '/type/' + type,
      params,
    })
  }

  getStatInfo(clientId) {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/${clientId}/information`,
    })
  }
}

export default new ClientService()
