export const getList = (state) => state.list

export const getTotal = (state) => state.total

export const getTotalRecords = (state) => state.totalRecords

export const isExporting = (state) => state.isExporting

export const dataCountedPerState = (state) => state.dataCountedPerState

export const getTotalFiltered = (state) => state.getTotalFiltered
