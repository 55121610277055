import ContractService from '../../../service/ContractService'
import * as mutationTypes from './types'

export const search = async ({ commit }, payload = {}) => {
  const { data } = await ContractService.search({ ...payload })
  commit('setAll', data)

  return data.items
}

export const getAll = async ({ commit }) => {
  const { data } = await ContractService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await ContractService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, { item, callback }) => {
  const { data } = await ContractService.create(item)

  typeof callback === 'function'
    ? await callback('add', data)
    : commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async (
  { commit },
  { item: { _id, ...payload }, callback },
) => {
  const { data } = await ContractService.update(_id, payload)

  typeof callback === 'function'
    ? await callback('update', data)
    : commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await ContractService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const exportCsv = async ({ commit, rootState }, { companyId }) => {
  try {
    commit(mutationTypes.exportCsv.requesting)
    const res = await ContractService.exportContract(companyId)
    commit(mutationTypes.exportCsv.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportCsv.failure, { payload: e, rootState })
  }
}
