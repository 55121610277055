import ConnectorService from '../../../service/ConnectorService'

export const getAll = async ({ commit }) => {
  const { data } = await ConnectorService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getByCompany = async ({ commit }, companyId) => {
  const { data } = await ConnectorService.getByCompany(companyId)
  commit('SET_CURRENT_CONNECTOR', data)

  return data
}
