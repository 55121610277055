import BaseService from '@/service/BaseService'
import { protectedRequest } from '@/boot/axios'

class PrebillingService extends BaseService {
  constructor() {
    super('production')
  }

  countPerState(payload) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/count-per-state`,
      data: {
        ...payload,
      },
    })
  }

  fetchPSPM(payload) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/pspm-list-from-pipm`,
      data: {
        ...payload,
      },
    })
  }
}

export default new PrebillingService()
