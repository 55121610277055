import UserService from '../../../service/UserService'
import * as mutationTypes from './types'

export const getList = async ({ commit }, query) => {
  const { data } = await UserService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await UserService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async (
  { commit, rootState },
  { _id, ...payload },
) => {
  try {
    commit(mutationTypes.updateUser.requesting)
    const { data } = await UserService.update(_id, payload)
    commit(mutationTypes.updateUser.success, { payload: data, rootState })

    return data
  } catch (e) {
    commit(mutationTypes.updateUser.failure, { payload: e, rootState })
    throw e
  }
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await UserService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const getUserByCompany = async (context, { companies, userId }) => {
  const { data } = await UserService.getUserByCompany(companies, userId)

  return data.items
}

export const search = async (context, query) => {
  const { data } = await UserService.searchUser(query)

  return data.items
}

export const getMe = async (context, token) => {
  const { data } = await UserService.getMe(token)

  return data
}

export const exportCsv = async ({ commit, rootState }) => {
  try {
    commit(mutationTypes.exportCsv.requesting)
    const res = await UserService.export()
    commit(mutationTypes.exportCsv.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportCsv.failure, { payload: e, rootState })
  }
}

export const importCsv = async (context, formData) => {
  const { data } = await UserService.import(formData)

  return data
}

export const hasUnseenFeature = async ({ commit }) => {
  const { data } = await UserService.hasUnseenFeature()
  commit('SET_UNSEEN_FEATURE', data)

  return data
}
