import { useLocalStorage } from '@vueuse/core'
import AuthService from '../../../service/AuthService'
import * as mutationTypes from './types'
import GoogleAuthService from '@/service/GoogleAuthService'
import UserService from '@/service/UserService'

export async function login({ commit, rootState }, payload) {
  try {
    commit(mutationTypes.authLogin.requesting)
    const res = await AuthService.login(payload)
    commit(mutationTypes.authLogin.success, { payload: res, rootState })
  } catch (e) {
    commit(mutationTypes.authLogin.failure, { payload: e, rootState })
    throw e
  }
}

export async function googleAuthentication({ commit, rootState }, payload) {
  try {
    commit(mutationTypes.googleAuth.requesting)
    const res = await GoogleAuthService.login(payload)
    commit(mutationTypes.googleAuth.success, { payload: res, rootState })

    return res.data
  } catch (e) {
    commit(mutationTypes.googleAuth.failure, { payload: e, rootState })
    throw e
  }
}

export async function updateAuthData({ commit }, payload) {
  const { data } = await UserService.getMe()
  commit(mutationTypes.updateAuthData, { ...payload, user: data?.user })
}

export function logout({ commit }, isForced = false) {
  commit(mutationTypes.logout)
  commit(mutationTypes.setIsForcedLogout, isForced)
}

export function setIsForcedLogout({ commit }, isForced) {
  commit(mutationTypes.setIsForcedLogout, isForced)
}

export async function refresh(
  { commit, rootState },
  { accessToken, refreshToken },
) {
  try {
    commit(mutationTypes.refreshToken.requesting)
    const res = await AuthService.refreshToken(accessToken, refreshToken)
    commit(mutationTypes.refreshToken.success, { payload: res, rootState })

    return res.data
  } catch (e) {
    commit(mutationTypes.refreshToken.failure, { payload: e, rootState })
    throw e
  }
}

export function setCurrentCompany({ commit, state }, payload) {
  // Resets listing states on changing company
  if (
    state.currentCompany &&
    payload &&
    state.currentCompany._id !== payload._id
  ) {
    const stateListings = useLocalStorage('state-listings', [])

    stateListings.value.forEach((stateKey) => {
      const state = useLocalStorage(stateKey, null)

      state.value = null
    })

    stateListings.value = []
  }

  commit(mutationTypes.setCurrentCompany, payload)
}
