export const isLoggingIn = (state) => state.isLoggingIn

export const isLoggedIn = (state) => state.isLoggedIn

export const isLoggedInWithGoogle = (state) => state.isLoggedInWithGoogle

export const authData = (state) => state.data

export const userInitials = (state) =>
  state.data
    ? `${state.data.user.first_name
        .charAt(0)
        .toUpperCase()}${state.data.user.last_name.charAt(0).toUpperCase()}`
    : ''

export const userFullName = (state) =>
  state.data &&
  `${state.data.user.first_name} ${state.data.user.last_name
    .charAt(0)
    .toUpperCase()}.`

export const isForcedLogout = (state) => state.isForcedLogout

export const redirect = (state) => state.redirect

export const companies = (state) => state.companies
