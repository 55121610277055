import moment from 'moment'
import { diff } from 'radash'

export const dateFormat = (date, format) => {
  const offset = moment().utcOffset()

  return date ? moment(date).utcOffset(offset).format(format) : ''
}

export const truncate = (value, limit = 10) => {
  return value ? value.slice(0, limit) + '...' : value
}

export const filterArray = (arr1, arr2 = [], excludeFromList = []) => {
  const byId = (item) => item._id

  return diff(diff(arr1, arr2, byId), excludeFromList, byId)
}
