import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class ContractService extends BaseService {
  constructor() {
    super('contracts')
  }

  exportContract(companyId = null) {
    return protectedRequest({
      timeout: 300000,
      method: 'GET',
      url: `${this._apiUrl}/export`,
      responseType: 'blob',
      params: {
        companyId,
      },
    })
  }

  nodes(id = null, { filters, ...query }) {
    const url = [this._apiUrl, 'nodes']

    if (id) url.push(id)

    return protectedRequest({
      method: 'post',
      url: url.join('/'),
      params: query,
      data: { filters },
    })
  }
}

export default new ContractService()
