import { breadcrumbs } from '@/common/constants/breadcrumb.constant'

export const SET_CURRENT = (state, current) => {
  // Current should be typeof string or [{label: '', url: ''}]
  if (typeof current === 'string') {
    state.current = breadcrumbs[current]
  } else if (Array.isArray(current)) {
    state.current = current
  }
}
