import * as mutationTypes from './types'
import AccountService from '@/service/AccountService'

export const initialization = async (
  { commit, rootState },
  { token, ...payload },
) => {
  try {
    commit(mutationTypes.initAccount.requesting)
    const { data } = await AccountService.initialization(payload, token)
    commit(mutationTypes.initAccount.success, { payload: data, rootState })

    return data
  } catch (e) {
    commit(mutationTypes.initAccount.failure, { payload: e, rootState })
    throw e
  }
}

export const getCompanies = async ({ commit }, token) => {
  const { data } = await AccountService.getCompanies(token)
  commit(mutationTypes.setCompanies, data)

  return data.items
}

export const getUserByCompany = async (
  { commit },
  { companies, userId, token },
) => {
  const { data } = await AccountService.getUserByCompany(
    companies,
    userId,
    token,
  )
  commit(mutationTypes.setUsers, data)

  return data.items
}

export const getMe = async (context, token) => {
  const { data } = await AccountService.getMe(token)

  return data
}
