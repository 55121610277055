export default {
  "common": {
    "boolean": {
      "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
    },
    "confirmation": {
      "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to quit without saving?"])}
    },
    "dropdown": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-- Select an option --"])},
      "blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-- Select an option --"])}
    },
    "billingMethods": {
      "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])}
    },
    "massActions": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "editQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Quote"])},
      "generateInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Invoice"])},
      "updateQtyToBeSoldOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Qty To Be Sold Out"])},
      "updateQtyOperatedFromBigQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Qty Operated From Big Query"])},
      "copyProductionToFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to Future"])},
      "addMapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Mapping"])}
    },
    "massActionsStats": {
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
      "ignored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignored"])},
      "errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed"])}
    },
    "filters": {
      "isEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is empty"])}
    }
  },
  "entities": {
    "common": {
      "item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["item"]), _normalize(["items"])])}
    },
    "baseProject": {
      "services": {
        "billingTypes": {
          "pre_billed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-billed"])},
          "on_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On progress"])}
        },
        "billingFrequencyPeriods": {
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
          "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarterly"])},
          "semesterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semesterly"])},
          "annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annually"])}
        },
        "billingFrequencyTimings": {
          "begin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin"])},
          "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])}
        },
        "feeScopes": {
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
          "revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue"])},
          "roi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROI"])},
          "media_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Spent"])},
          "percentage_execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% Execution"])},
          "time_tracking_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time tracking (day)"])},
          "time_tracking_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time tracking (hour)"])},
          "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])},
          "other_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other lead"])},
          "number_of_campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of campaigns"])}
        },
        "feePricingTypes": {
          "tiered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiered"])},
          "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
          "amount_per_tiered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount per tiered"])}
        },
        "tierModes": {
          "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])},
          "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])}
        },
        "fee": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["fee"]), _normalize(["fees"])])},
        "fee_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no fees"]), _normalize(["one fee"]), _normalize([_interpolate(_named("count")), " fees"])])},
        "tier": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["tier"]), _normalize(["tiers"])])},
        "tier_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no tiers"]), _normalize(["one tier"]), _normalize([_interpolate(_named("count")), " tiers"])])},
        "feesTemplateTypes": {
          "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])},
          "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
          "percentage_fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% + Fixed"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
          "time_tracking_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time tracking (day)"])},
          "time_tracking_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time tracking (hour)"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
          "amount_fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount + Fixed"])}
        }
      }
    },
    "production": {
      "billingStatuses": {
        "draft_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft invoice"])},
        "already_invoiced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already invoiced"])},
        "to_be_invoiced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be invoiced"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])}
      },
      "paymentStatuses": {
        "pending_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending payment"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])}
      },
      "prebilling": {
        "current_month_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Month Billing"])},
        "moved_out_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moved Out Billing"])},
        "moved_in_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moved In Billing"])}
      }
    },
    "services": {
      "projectType": {
        "simpleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PS"])},
        "internalProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PI"])},
        "programManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM"])}
      }
    },
    "entityBillingSoftware": {
      "connectorTypes": {
        "file-sage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Sage"])},
        "api-quickbooks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Quickbooks"])},
        "api-fattureincloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API FattureInCloud"])}
      }
    },
    "contact": {
      "alreadyExisting": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["We have found another contact with this email:"]), _normalize(["We have found other contacts with these emails:"])])}
    },
    "dashboard": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee"])},
      "dbu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DBU"])},
      "biller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biller"])},
      "team_leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Leader"])},
      "budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget"])}
    },
    "activity": {
      "type": {
        "programManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Management"])},
        "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
        "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
        "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])}
      }
    }
  }
}