import ScriptRunnerService from '../../../service/ScriptRunnerService'

export const getAll = async ({ commit }) => {
  const { data } = await ScriptRunnerService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await ScriptRunnerService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await ScriptRunnerService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await ScriptRunnerService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await ScriptRunnerService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}
