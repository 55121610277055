export const SET_LIST = (state, { items, count }) => {
  state.list = [...items]
  state.totalRecords = count
}

export const UPDATE_LIST = (state, item) => {
  const updateIndex = state.list.findIndex((el) => el._id === item._id)
  state.list[updateIndex] = item
  state.list = [...state.list]
}
