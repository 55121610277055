import PlatformService from '../../../service/PlatformService'

export const getAll = async ({ commit }) => {
  const { data } = await PlatformService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const search = async ({ commit }, payload = {}) => {
  const { data } = await PlatformService.search({ ...payload })
  commit('setAll', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await PlatformService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await PlatformService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await PlatformService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await PlatformService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}
