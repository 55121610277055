import IntercoService from '../../../service/IntercoService'

export const getAll = async ({ commit }) => {
  const { data } = await IntercoService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await IntercoService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}
