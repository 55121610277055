import PivotTableConfigService from '../../../service/PivotTableConfigService'

export const addItem = async ({ commit }, item) => {
  const { data } = await PivotTableConfigService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const getAll = async ({ commit }) => {
  const { data } = await PivotTableConfigService.getAll()
  commit('SET_LIST', data)

  return data.menuBarItems
}

export const getList = async ({ commit }, query) => {
  const { data } = await PivotTableConfigService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await PivotTableConfigService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await PivotTableConfigService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}
