import QuickbookService from '../../../service/QuickbookService'

export const getAll = async ({ commit }) => {
  const { data } = await QuickbookService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const authorizeUri = async (context, softwareId) => {
  const { data } = await QuickbookService.authorizeUri(softwareId)

  return data
}

export const revokeAccess = async ({ commit }, softwareId) => {
  const { data } = await QuickbookService.revokeAccess(softwareId)
  commit('UPDATE_LIST', data)

  return data
}

export const getQuickbookCustomers = async (context, connector) => {
  const { data } = await QuickbookService.getQuickbookCustomers(connector)

  return data
}
