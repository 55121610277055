import { protectedRequest } from '../boot/axios'

class QuickbookService {
  constructor() {
    this._apiUrl = '/quickbooks'
  }

  getAll() {
    return protectedRequest({
      method: 'GET',
      url: this._apiUrl,
    })
  }

  authorizeUri(softwareId) {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/software/${softwareId}/authorize`,
    })
  }

  revokeAccess(softwareId) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/software/${softwareId}/revoke`,
    })
  }

  getQuickbookCustomers(connector) {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/${connector}/customers`,
    })
  }
}

export default new QuickbookService()
