import LocalClientService from '../../../service/LocalClientService'
import * as mutationTypes from '@/store/modules/localClient/types'

export const getAll = async ({ commit }) => {
  const { data } = await LocalClientService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const search = async ({ commit }, payload = {}) => {
  const { data } = await LocalClientService.search({ ...payload })
  commit('setAll', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await LocalClientService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const getByCompany = async ({ commit }, company) => {
  const { data } = await LocalClientService.getByCompany(company)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await LocalClientService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await LocalClientService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const getItemById = async ({ commit }, id) => {
  const { data } = await LocalClientService.getById(id)
  commit('SET_CURRENT', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await LocalClientService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const setCurrent = ({ commit }, payload) => {
  commit('SET_CURRENT', payload)
}

export const importCsv = async (context, formData) => {
  const { data } = await LocalClientService.import(formData)

  return data
}

export const importWithFileConnector = async (
  context,
  { connector, formData },
) => {
  const { data } = await LocalClientService.importWithFileConnector(
    connector,
    formData,
  )

  return data
}

export const syncUsingApi = async (context, { softwareId, company }) => {
  const { data } = await LocalClientService.syncUsingApi(softwareId, company)

  return data
}

export const exportCsv = async ({ commit, rootState }, { companyId }) => {
  try {
    commit(mutationTypes.exportCsv.requesting)
    const res = await LocalClientService.export(companyId)
    commit(mutationTypes.exportCsv.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportCsv.failure, { payload: e, rootState })
  }
}
