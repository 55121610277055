<template>
  <div class="layout-topbar shadow-4">
    <div class="layout-topbar-left">
      <router-link
        :to="{ name: 'dashboard', params: { idCompany: idCompany } }"
        class="layout-topbar-logo"
      >
        <img
          id="logo"
          alt="happy-layout"
          src="images/logo.png"
          style="height: 2.25rem"
        />
      </router-link>

      <a
        v-ripple
        class="layout-menu-button shadow-6 p-ripple"
        @click="onMenuButtonClick($event)"
      >
        <i class="pi pi-chevron-right"></i>
      </a>

      <a
        v-ripple
        class="layout-topbar-mobile-button p-ripple"
        @click="onTopbarMobileButtonClick($event)"
      >
        <i class="pi pi-ellipsis-v fs-large"></i>
      </a>
    </div>

    <div
      :class="{ 'layout-topbar-mobile-active': mobileTopbarActive }"
      class="layout-topbar-right"
    >
      <div class="layout-topbar-actions-left">
        <Dropdown
          :model-value="currentCompany"
          :options="userCompanies"
          data-key="_id"
          option-label="name"
          placeholder="Select a company"
          @change="setCurrentCompany"
        />
      </div>

      <div class="layout-topbar-actions-right">
        <ul class="layout-topbar-items">
          <li class="layout-topbar-item">
            <a
              v-ripple
              class="layout-topbar-action rounded-circle p-ripple"
              @click="goToFeatureList"
            >
              <i
                :class="{
                  'pi-flag-fill': hasUnseenFeature,
                  'pi-flag': !hasUnseenFeature,
                }"
                :style="{
                  color: hasUnseenFeature ? '#e91e63' : '#000000',
                }"
                class="pi fs-large"
              ></i>
            </a>
          </li>
          <li class="layout-topbar-item">
            <a
              v-ripple
              class="layout-topbar-action rounded-circle p-ripple"
              href="https://sites.google.com/labelium.com/happyultima-userguide/home"
              target="_blank"
            >
              <i class="pi pi-question fs-large"></i>
            </a>
          </li>

          <li class="layout-topbar-item notifications">
            <a
              v-ripple
              class="layout-topbar-action rounded-circle p-ripple"
              @click="onTopbarItemClick($event, 'connectors')"
            >
              <span
                v-if="quickbookConnectionInfo.allConnected"
                v-badge.success
                class="p-overlay-badge"
              >
                <i class="pi pi-link fs-large"></i>
              </span>
              <span
                v-else-if="quickbookConnectionInfo.connected"
                v-badge.warning
                class="p-overlay-badge"
              >
                <i class="pi pi-link fs-large"></i>
              </span>
              <span
                v-else-if="quickbookConnectionInfo.connected === 0"
                v-badge.danger
                class="p-overlay-badge"
              >
                <i class="pi pi-link fs-large"></i>
              </span>
            </a>

            <transition name="layout-topbar-action-panel">
              <ul
                v-show="activeTopbarItem === 'connectors'"
                class="layout-topbar-action-panel shadow-6"
              >
                <li class="layout-topbar-action-item">
                  <router-link
                    :to="{
                      name: 'quickbook_management',
                      params: { idCompany: idCompany },
                    }"
                    class="flex flex-row align-items-center"
                  >
                    <img src="images/icones/quickbook.svg" alt="Quickbook" />
                    <div
                      :class="{ 'ml-3': !isRTL, 'mr-3': isRTL }"
                      class="flex flex-column"
                      style="flex-grow: 1"
                    >
                      <div
                        class="flex align-items-center justify-content-between mb-1"
                      >
                        <span class="fs-small font-bold">Quickbook</span>
                      </div>
                      <span class="fs-small"
                        >{{
                          quickbookConnectionInfo.allConnected
                            ? 'All '
                            : `${quickbookConnectionInfo.connected} of ${quickbookConnectionInfo.total} `
                        }}
                        connected</span
                      >
                    </div>
                  </router-link>
                </li>
              </ul>
            </transition>
          </li>

          <li class="layout-topbar-item">
            <a
              v-ripple
              class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-ripple"
              @click="onTopbarItemClick($event, 'profile')"
            >
              <user-avatar />
            </a>

            <transition name="layout-topbar-action-panel">
              <ul
                v-show="activeTopbarItem === 'profile'"
                class="layout-topbar-action-panel shadow-6"
              >
                <li>
                  <a
                    v-ripple
                    class="layout-topbar-action-item flex flex-row align-items-center p-ripple"
                    @click="logout()"
                  >
                    <i
                      :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"
                      class="pi pi-power-off"
                    ></i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </transition>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <FeatureModal />
</template>

<script>
import { computed, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import UserAvatar from './components/ui/UserAvatar.vue'
import useAuthentication from './composables/useAuthentication'
import useStorage from './composables/useStorage'
import UserService from '@/service/UserService'
import FeatureModal from '@/components/modal/FeatureModal.vue'

export default {
  components: {
    FeatureModal,
    UserAvatar,
  },

  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },

    topbarMenuActive: {
      type: Boolean,
      default: false,
    },

    activeTopbarItem: String,
    mobileTopbarActive: Boolean,
    searchActive: Boolean,
  },

  emits: [
    'menubutton-click',
    'topbar-menubutton-click',
    'topbaritem-click',
    'rightpanel-button-click',
    'topbar-mobileactive',
    'search-click',
    'search-toggle',
    'search-hide',
  ],

  setup() {
    const router = useRouter()
    const store = useStore()
    const toast = useToast()
    const route = useRoute()
    const { logout } = useAuthentication()
    const { setStorageItem, getStorageItem } = useStorage()
    const currentCompany = inject('currentCompany')
    const idCompany = computed(() => currentCompany.value._id)
    const goToFeatureList = () => {
      router.push({
        name: 'all_features_management',
      })
    }
    const setCurrentCompany = async (event) => {
      try {
        const selectedCompany = {
          code: event.value.code,
          _id: event.value._id,
          name: event.value.name,
        }
        setStorageItem('CURRENT_COMPANY', selectedCompany)
        await store.dispatch('auth/setCurrentCompany', event.value)
        await UserService.changeCompany(event.value._id)
        const authData = getStorageItem('AUTH_DATA')
        authData.user.selectedCompany = selectedCompany
        setStorageItem('AUTH_DATA', authData)
        await router.replace({
          name: route.name,
          params: { idCompany: event.value._id },
        })
      } catch (e) {
        toast.add({
          severity: 'error',
          summary: "Can't change company",
          detail: `Something went wrong when changing company.`,
        })
      }
    }

    return {
      logout,
      setStorageItem,
      idCompany,
      currentCompany,
      goToFeatureList,
      setCurrentCompany,
    }
  },

  data() {
    return {
      searchText: '',
    }
  },

  computed: {
    topbarItemsClass() {
      return [
        'topbar-items animated fadeInDown',
        {
          'topbar-items-visible': this.topbarMenuActive,
        },
      ]
    },

    userCompanies() {
      const data = this.$store.getters['auth/authData']

      if (data) {
        return data.user.companies
      }

      return []
    },

    isRTL() {
      return this.$appState.RTL
    },

    quickbookConnectors() {
      return this.$store.getters['quickbook/getList']
    },

    quickbookConnectionInfo() {
      const connected = this.quickbookConnectors.filter(
        (el) => !!el.realm_id,
      ).length

      return {
        total: this.quickbookConnectors.length,
        connected,
        allConnected: this.quickbookConnectors.length === connected,
      }
    },

    hasUnseenFeature() {
      return this.$store.getters['user/hasUnseenFeature']
    },
  },

  async created() {
    await this.$store.dispatch('quickbook/getAll')
    await this.$store.dispatch('user/hasUnseenFeature')
  },

  methods: {
    onSearchContainerClick(event) {
      this.$emit('search-click', event)
    },

    changeSearchActive(event) {
      this.$emit('search-toggle', event)
    },

    onMenuButtonClick(event) {
      this.$emit('menubutton-click', event)
    },

    onTopbarMenuButtonClick(event) {
      this.$emit('topbar-menubutton-click', event)
    },

    onTopbarItemClick(event, item) {
      if (item === 'search') {
        this.$emit('search-toggle', event)
      }

      this.$emit('topbaritem-click', { originalEvent: event, item: item })
    },

    onTopbarMobileButtonClick(event) {
      this.$emit('topbar-mobileactive', event)
    },

    onRightPanelButtonClick(event) {
      this.$emit('rightpanel-button-click', event)
    },

    onSearchKeydown(event) {
      if (event.keyCode == 13) {
        this.$emit('search-toggle', event)
      }
    },

    onEnter() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.$el.focus()
      }
    },
  },
}
</script>
