import ProductionService from '../../../service/ProductionService'

export const getAll = async ({ commit }) => {
  const { data } = await ProductionService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await ProductionService.paginatedSearchPostProduction(query)
  commit('SET_LIST', data)

  return data
}

export const getTotalFiltered = async ({ commit }, query) => {
  const { data } = await ProductionService.totalPostProduction(query)
  commit('SET_TOTAL_FILTERED', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await ProductionService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, updatedata) => {
  const { data } = await ProductionService.updateByFeeAndDate(updatedata)
  commit('UPDATE_LIST', data)

  return data
}

export const massUpdateItems = async ({ commit }, updatedata) => {
  const { data } = await ProductionService.massUpdate(updatedata)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await ProductionService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const getTotal = async ({ commit }, query) => {
  const { data } = await ProductionService.totalPostProduction(query)
  commit('SET_TOTAL', data)

  return data
}
