import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class BudgetService extends BaseService {
  constructor() {
    super('budget')
  }

  getBudgetByCompany(company) {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/${company}/budget`,
    })
  }

  getBudgetEntries(company, budgetId, params = {}) {
    const url = params.companies
      ? `${this._apiUrl}/${budgetId}/budget-entries`
      : `${this._apiUrl}/${company}/${budgetId}/budget-entries`

    return protectedRequest({
      method: 'GET',
      url,
      params,
    })
  }

  updateBudgetEntryRepart(data, budgetId, budgetEntryId, repartId) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/${budgetId}/budget-entry/${budgetEntryId}/repart/${repartId}`,
      data,
    })
  }

  updateBudgetEntryDates(data, budgetId, budgetEntryId, repartId) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/${budgetId}/budget-entry/${budgetEntryId}/repart/${repartId}/dates`,
      data,
    })
  }

  updateBudgetEntry(budgetId, budgetEntryId, data) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/${budgetId}/budget-entry/${budgetEntryId}`,
      data,
    })
  }

  getBudgetDashboard(company, filters = {}) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/${company}/budget-dashboard`,
      data: filters,
    })
  }

  exportAll(company, budgetId) {
    return protectedRequest({
      timeout: 300000,
      method: 'GET',
      url: `${this._apiUrl}/${budgetId}/export-all`,
      params: {
        company,
      },
      responseType: 'blob',
    })
  }
}

export default new BudgetService()
