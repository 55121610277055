<template>
  <div class="pages-body error-page flex flex-column">
    <div
      class="topbar p-3 flex justify-content-between flex-row align-items-center"
    >
      <div class="topbar-left ml-3 flex" @click="goDashboard">
        <div class="logo">
          <img alt="" src="assets/layout/images/logo2x.png" />
        </div>
      </div>
      <div class="topbar-right mr-3 flex">
        <Button
          class="p-button-text p-button-plain topbar-button"
          label="DASHBOARD"
          type="button"
          @click="goDashboard"
        ></Button>
      </div>
    </div>

    <div class="align-self-center mt-auto mb-auto">
      <div class="pages-panel card flex flex-column">
        <div class="pages-header px-3 py-1">
          <h2>ERROR</h2>
        </div>
        <div class="card mt-3 px-6">
          <img alt="" src="assets/layout/images/pages/error.png" />
        </div>
        <div class="pages-detail pb-6">
          Requested resource is not available.
        </div>
        <Button
          class="p-button-text"
          label="GO BACK TO DASHBOARD"
          type="button"
          @click="goDashboard"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue'

export default {
  setup() {
    const currentCompany = inject('currentCompany')
    const idCompany = computed(() => currentCompany.value._id)

    return { idCompany }
  },

  methods: {
    goDashboard() {
      this.$router.push({
        name: 'dashboard',
        params: { idCompany: this.idCompany },
      })
    },
  },
}
</script>

<style scoped></style>
