import { protectedRequest } from '../boot/axios'
import BaseService from './BaseService'

class CompanyService extends BaseService {
  constructor() {
    super('companies')
  }

  entityBillingSoftware(id) {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/${id}/entity-billing-software`,
    })
  }

  getAllWithEntity() {
    return protectedRequest({
      method: 'GET',
      url: this._apiUrl + '/entity',
    })
  }
}

export default new CompanyService()
