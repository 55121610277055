import { exportCsv, updateUser } from './types'

export default {
  [updateUser.requesting](state) {
    state.isUpdatingUser = true
  },
  [updateUser.success](state, { rootState, payload }) {
    state.isUpdatingUser = false
    const updateIndex = state.list.findIndex((el) => el._id === payload._id)
    state.list[updateIndex] = payload
    state.list = [...state.list]
    rootState.message = { text: 'User updated successfully', type: 'success' }
  },
  [updateUser.failure](state, { rootState, payload }) {
    state.isUpdatingUser = false
    rootState.message = { text: payload.message, type: 'error' }
  },
  [exportCsv.requesting](state) {
    state.isExporting = true
  },
  [exportCsv.success](state, { rootState }) {
    rootState.message = { text: 'Exported successfully', type: 'error' }
    state.isExporting = false
  },
  [exportCsv.failure](state, { rootState, payload }) {
    rootState.message = { text: payload.message, type: 'error' }
    state.isExporting = false
  },
  [exportCsv.requesting](state) {
    state.isExporting = true
  },
  ADD_USER(state, data) {
    state.list = [data, ...state.list]
  },

  DELETE_USER(state, id) {
    state.list = state.list.filter((el) => el._id != id)
  },
  SET_LIST(state, { items, count }) {
    state.list = [...items]
    state.totalRecords = count
  },
  ADD_TO_LIST(state, item) {
    state.list = [item, ...state.list]
    state.totalRecords = state.totalRecords + 1
  },
  REMOVE_FROM_LIST(state, id) {
    state.list = state.list.filter((el) => el._id !== id)
  },
  SET_UNSEEN_FEATURE(state, item) {
    state.hasUnseenFeature = item
  },
  SET_CURRENT_COMPANY(state, item) {
    state.currentCompany = item
  },
}
