export function networkMutationsCreator(actionType) {
  const requesting = actionType + '_REQUESTING'
  const success = actionType + '_SUCCESS'
  const failure = actionType + '_FAILURE'

  return {
    requesting,
    success,
    failure,
  }
}
