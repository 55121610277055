import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class RoleService extends BaseService {
  constructor() {
    super('roles')
  }

  // other implementation goes here
  updateAcl(id, data) {
    return protectedRequest({
      method: 'PATCH',
      url: `${this._apiUrl}/${id}/acl`,
      data,
    })
  }
}

export default new RoleService()
