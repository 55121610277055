import BaseService from '@/service/BaseService'
import { protectedRequest } from '@/boot/axios'

class ImportTemplateService extends BaseService {
  constructor() {
    super('import-templates')
  }

  templates() {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/templates`,
    })
  }

  getTemplate(filters) {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/template`,
      params: filters,
    })
  }
}

export default new ImportTemplateService()
