import { publicRequest } from '@/boot/axios'

class GoogleAuthService {
  constructor() {
    this._apiUrl = '/google'
  }

  login(idToken) {
    return publicRequest({
      method: 'POST',
      url: this._apiUrl + '/auth',
      data: { idToken },
    })
  }
}

export default new GoogleAuthService()
