<template>
  <div class="pages-body accessdenied-page flex flex-column">
    <div
      class="topbar p-3 flex justify-content-between flex-row align-items-center"
    >
      <div class="topbar-left ml-3 flex">
        <div class="logo">
          <img alt="" src="images/logo.png" />
        </div>
      </div>
    </div>

    <div class="align-self-center mt-auto mb-auto">
      <div class="pages-panel card flex flex-column align-items-start">
        <div class="card mt-3 px-6 align-self-center">
          <img
            alt=""
            src="images/illustrations/pending-approval.svg"
            style="height: 25vh"
          />
        </div>
        <h4 class="mt-0">Dear future user,</h4>
        <div class="pages-detail pb-2 text-left">
          Your account is awaiting validation.<br />
          Once validated, you will receive an email alert.
        </div>
        <div class="mb-3">Best regards,</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import useAuthentication from '@/composables/useAuthentication'

export default {
  setup() {
    const { logout } = useAuthentication()
    onMounted(() => {
      logout(true)
    })
  },
}
</script>

<style scoped></style>
