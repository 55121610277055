import { exportExcel } from './types'

export default {
  SET_LIST(state, data) {
    state.list = [...data.items]
    state.months = [...data.months]
    state.totalRecords = data.count
  },

  SET_COUNT(state, data) {
    state.totalRecords = data.count
  },

  ADD_TO_LIST(state, item) {
    state.list = [item, ...state.list]
    state.totalRecords = state.totalRecords + 1
  },

  UPDATE_LIST(state, item) {
    const updateIndex = state.list.findIndex((el) => el._id === item._id)
    state.list[updateIndex] = item
    state.list = [...state.list]
  },

  REMOVE_FROM_LIST(state, id) {
    state.list = state.list.filter((el) => el._id !== id)
  },

  [exportExcel.requesting](state) {
    state.isExporting = true
  },

  [exportExcel.success](state, { rootState }) {
    rootState.message = { text: 'Exported successfully', type: 'error' }
    state.isExporting = false
  },

  [exportExcel.failure](state, { rootState, payload }) {
    rootState.message = { text: payload.message, type: 'error' }
    state.isExporting = false
  },
}
