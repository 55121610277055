export default function useStorage() {
  const setStorageItem = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data))
  }

  const clearStorage = () => {
    localStorage.clear()
  }

  const getStorageItem = (key) => {
    if (key) {
      const data = localStorage.getItem(key)

      return (data && JSON.parse(data)) || null
    }

    return localStorage
  }

  const removeStorageItem = (key) => {
    if (key) {
      localStorage.removeItem(key)
    } else {
      clearStorage()
    }
  }

  return {
    setStorageItem,
    getStorageItem,
    clearStorage,
    removeStorageItem,
  }
}
