<template>
  <Avatar
    v-if="userAvatar"
    :image="userAvatar"
    :style="style"
    shape="circle"
    v-bind="$attrs"
  />
  <Avatar
    v-else
    :label="userInitials"
    :style="style"
    shape="circle"
    v-bind="$attrs"
  />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  inheritAttrs: false,

  props: {
    user: null,

    style: {
      type: String,
      default: 'background: #dee2e6;color: #495057;',
    },
  },

  setup(props) {
    const store = useStore()

    const userInitials = computed(() => {
      if (props.user) {
        return `${props.user.first_name
          .charAt(0)
          .toUpperCase()}${props.user.last_name.charAt(0).toUpperCase()}`
      }

      return store.getters['auth/userInitials']
    })

    const userAvatar = computed(
      () => store.getters['auth/authData']?.user?.avatarUrl,
    )

    return { userInitials, userAvatar }
  },
}
</script>
