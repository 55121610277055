import ContactService from '../../../service/ContactService'
import * as mutationTypes from './types'

export const getAll = async ({ commit }) => {
  const { data } = await ContactService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await ContactService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await ContactService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await ContactService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const getItemById = async ({ commit }, id) => {
  const { data } = await ContactService.getById(id)
  commit('SET_CURRENT', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await ContactService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const setCurrent = ({ commit }, payload) => {
  commit('SET_CURRENT', payload)
}

export const importCsv = async (context, formData) => {
  const { data } = await ContactService.import(formData)

  return data
}

export const exportCsv = async ({ commit, rootState }, { companyId }) => {
  try {
    commit(mutationTypes.exportCsv.requesting)
    const res = await ContactService.export(companyId)
    commit(mutationTypes.exportCsv.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportCsv.failure, { payload: e, rootState })
  }
}
