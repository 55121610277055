<template>
  <AppSubmenu
    :is-slim-or-hor-item-click="isSlimOrHorItemClick"
    :items="model"
    :menu-active="active"
    :menu-mode="menuMode"
    :mobile-menu-active="mobileMenuActive"
    :parent-menu-item-active="true"
    :root="true"
    class="layout-menu"
    @menuitem-click="onMenuItemClick"
    @root-menuitem-click="onRootMenuItemClick"
  />
</template>

<script>
import AppSubmenu from './AppSubmenu'

export default {
  name: 'AppMenu',

  components: {
    AppSubmenu: AppSubmenu,
  },

  props: {
    model: Array,
    menuMode: String,
    active: Boolean,
    mobileMenuActive: Boolean,
    isSlimOrHorItemClick: Boolean,
  },

  emits: ['menu-click', 'menuitem-click', 'root-menuitem-click'],

  methods: {
    onMenuClick(event) {
      this.$emit('menu-click', event)
    },

    onMenuItemClick(event) {
      this.$emit('menuitem-click', event)
    },

    onRootMenuItemClick(event) {
      this.$emit('root-menuitem-click', event)
    },
  },
}
</script>

<style scoped></style>
