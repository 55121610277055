import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class ProgramManagementService extends BaseService {
  constructor() {
    super('program-managements')
  }

  saveFeesAsDraft(id, data) {
    return protectedRequest({
      method: 'PATCH',
      url: `${this._apiUrl}/${id}/fees`,
      data,
    })
  }

  submitFeesToPartner(id, data) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/${id}/fees/submit`,
      data,
    })
  }

  submitProducerValidation(id, data) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/${id}/validation/submit`,
      data,
    })
  }

  submitToAdminBiller(id, data) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/${id}/fees/biller/submit`,
      data,
    })
  }

  generateGlobalRepartition(id, data) {
    return protectedRequest({
      method: 'PATCH',
      url: `${this._apiUrl}/${id}/fees/generate-global-repartition`,
      data,
    })
  }

  addProductAfterValidate(id, data) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/${id}/fees/add-product-after-validate`,
      data,
    })
  }

  countPerState(payload) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/count-per-state`,
      data: {
        ...payload,
      },
    })
  }
}

export default new ProgramManagementService()
