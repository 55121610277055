import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class LocalProduct extends BaseService {
  constructor() {
    super('local-products')
    this.updateLocalProductFavorite = this.updateLocalProductFavorite.bind(this)
  }

  updateLocalProductFavorite(id, data) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/favorite/${id}`,
      data,
    })
  }
}

export default new LocalProduct()
