import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class LocalClientService extends BaseService {
  constructor() {
    super('local-clients')
    this.softDeleteAC = []
  }

  importWithFileConnector(connector, formData) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/import/file/${connector}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
  }

  getByCompany(companyId) {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/company/${companyId}`,
    })
  }

  isSoftDelete(id, data = {}) {
    this.cleanLocalClient()

    const abortController = new AbortController()
    this.softDeleteAC ??= []
    this.softDeleteAC.push(abortController)

    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/${id}/soft-delete`,
      data,
      signal: abortController.signal,
    })
  }

  cleanLocalClient() {
    if (this.softDeleteAC.length > 0) {
      this.softDeleteAC.forEach((ac) => ac.abort())
      this.softDeleteAC = []
    }
  }
}

export default new LocalClientService()
