import axios from 'axios'
import config from '@/config'
import store from '@/store'
import useStorage from '@/composables/useStorage'
import router from '@/router'
import useAuthentication from '@/composables/useAuthentication'

const { getStorageItem, setStorageItem } = useStorage()

const instance = axios.create({
  baseURL: config.API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(
  function (config) {
    // TO DO inject token here
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (
      error.response?.status === 401 &&
      error.response?.data?.instruction === 'refresh_token'
    ) {
      const tokens = getStorageItem('AUTH_DATA')?.tokens || null

      if (!tokens) throw new Error('Token missing')
      const authData = await store.dispatch('auth/refresh', tokens)
      setStorageItem('AUTH_DATA', authData)

      error.config.headers['Authorization'] =
        `Bearer ${authData.tokens.accessToken}`

      return axios(error.config)
    } else if (error.response?.status === 401) {
      // force logout of unauthenticated request
      const { logout } = useAuthentication()
      logout(true)
      await router.push({
        name: 'login',
        params: { message: error.response?.data?.message },
      })
    }

    if (error?.response?.data) return Promise.reject(error?.response?.data)

    return Promise.reject(error)
  },
)

const publicRequest = async (request) => {
  request.headers ??= {}
  request.headers['Happy-Api-Token'] = config.API_TOKEN

  const res = await instance.request(request)

  return res?.data || null
}

const protectedRequest = async (request, token = null) => {
  let access_token

  if (token) {
    access_token = token
  } else {
    access_token = getStorageItem('AUTH_DATA')?.tokens?.accessToken
  }

  request.headers ??= {}
  request.headers['Happy-Api-Token'] = config.API_TOKEN
  request.headers.Authorization = `Bearer ${access_token}`

  const { data } = await instance.request(request)

  return data
}

export { publicRequest, protectedRequest }
