export const defaultState = {
  data: null,
  isLoggingIn: false,
  isLoggedIn: false,
  isForcedLogout: false,
  redirect: '',
  isRefreshingToken: false,
  currentCompany: null,
}

export default {
  data: null,
  isLoggingIn: false,
  isLoggedIn: false,
  isForcedLogout: false,
  redirect: '',
  isRefreshingToken: false,
  currentCompany: null,
}
