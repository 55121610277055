import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class ProjectService extends BaseService {
  constructor() {
    super('projects')
  }

  submitServicesToAdminBillers(id, data) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}/${id}/services/billers/submit`,
      data,
    })
  }

  exportExcelForEditQuote(ids) {
    return protectedRequest({
      timeout: 300000,
      method: 'POST',
      url: `${this._apiUrl}/export-excel-for-edit-quote`,
      data: ids,
      responseType: 'blob',
    })
  }

  countPerState(payload) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/count-per-state`,
      data: {
        ...payload,
      },
    })
  }
}

export default new ProjectService()
