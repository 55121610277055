<template>
  <div class="pages-body login-page flex flex-column">
    <div
      class="topbar p-3 flex justify-content-between flex-row align-items-center"
    >
      <div class="topbar-left ml-3 flex">
        <div class="logo">
          <img alt="" src="images/logo.svg" />
        </div>
      </div>
    </div>

    <div class="align-self-center mt-auto mb-auto">
      <div class="pages-panel card flex flex-column">
        <div class="flex justify-content-center">
          <img alt="" height="75" src="images/logo-labelium.svg" />
        </div>
        <h4>Welcome</h4>

        <div class="pages-detail mb-5 px-6">
          Please use the form to sign-in
          <span class="font-bold">#HAPPY</span> network
        </div>

        <Message
          v-if="authMessage"
          :severity="authMessage.type"
          @close="clearAuthMessage"
          >{{ authMessage.text }}
        </Message>
        <Message v-if="routeMessage" severity="error"
          >{{ routeMessage }}
        </Message>

        <template v-if="isExternal">
          <div class="input-panel flex flex-column px-3">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-envelope"></i>
              </span>
              <span class="p-float-label">
                <InputText id="email" v-model="email" type="text" />
                <label for="email">Email</label>
              </span>
            </div>

            <div class="p-inputgroup mt-3 mb-5">
              <span class="p-inputgroup-addon">
                <i class="pi pi-lock"></i>
              </span>
              <span class="p-float-label">
                <InputText id="password" v-model="password" type="password" />
                <label for="password">Password</label>
              </span>
            </div>
          </div>

          <Button
            :loading="isLoggingIn"
            class="login-button px-3"
            label="LOGIN"
            @click="login(email, password)"
          />

          <div class="my-3">
            <p>Or</p>
          </div>
        </template>

        <GoogleLogin :callback="callback" class="tw-m-auto" />

        <Button
          class="p-button-rounded p-button-text flex justify-content-end"
          type="button"
          @click="useExternalLogin"
        >
          <span class="flex justify-content-end">Or</span>
          <span class="flex justify-content-end underline"
            >&nbsp;use login</span
          >
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import useAuthentication from '../composables/useAuthentication'

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const routeMessage = ref(route.params.message)
    const { isLoggingIn, authMessage, login, clearAuthMessage } =
      useAuthentication()

    const redirect = computed(() => store.getters['auth/redirect'])

    const callback = async (response) => {
      await store.dispatch('auth/googleAuthentication', response.credential)
      await router.push(redirect.value)
    }

    return {
      isLoggingIn,
      authMessage,
      routeMessage,
      login,
      clearAuthMessage,
      callback,
    }
  },

  data() {
    return {
      email: '',
      password: '',
      isExternal: false,
    }
  },

  beforeUnmount() {
    this.clearAuthMessage()
  },

  methods: {
    useExternalLogin() {
      this.isExternal = true
    },
  },
}
</script>
