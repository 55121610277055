<template>
  <div class="layout-footer flex align-items-center p-4 shadow-2">
    <img
      id="footer-logo"
      :src="'images/logo' + (layoutMode === 'light' ? '-dark' : '') + '.svg'"
      alt="happy-footer-logo"
      style="height: 3rem"
    />
  </div>
</template>

<script>
export default {
  name: 'AppFooter',

  props: {
    layoutMode: {
      type: String,
      default: 'light',
    },
  },

  computed: {
    isRTL() {
      return this.$appState.RTL
    },
  },
}
</script>

<style scoped></style>
