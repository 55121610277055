import ProductionService from '../../../service/ProductionService'
import * as mutationTypes from './types'

export const getAll = async ({ commit }) => {
  const { data } = await ProductionService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await ProductionService.paginatedSearchSales(query)
  commit('SET_LIST', data)

  return data
}

export const getTotalRecords = async ({ commit }, query) => {
  const { data } = await ProductionService.totalRecordsProduction(query)
  commit('SET_COUNT', data)

  return data
}

export const exportExcel = async ({ commit, rootState }, query) => {
  try {
    commit(mutationTypes.exportExcel.requesting)
    const res = await ProductionService.exportSalesExcel(query)
    commit(mutationTypes.exportExcel.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportExcel.failure, { payload: e, rootState })
  }
}
