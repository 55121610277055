import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { googleLogout } from 'vue3-google-login'
import useStorage from '@/composables/useStorage'
import localStore from '@/store'

export default function useAuthentication() {
  let store = useStore()
  const router = useRouter()
  const { clearStorage, getStorageItem } = useStorage()

  if (!store) store = localStore

  const isLoggingIn = computed(() => {
    return store.getters['auth/isLogginIn']
  })

  const isLoggedIn = computed(() => store.getters['auth/isLoggedIn'])
  const currentCompany = computed(() => store.getters['auth/currentCompany'])

  const authMessage = computed(() => {
    return store.getters['message']
  })

  const login = async (email, password) => {
    try {
      await store.dispatch('auth/login', { email, password })
      const currentCompany = getStorageItem('CURRENT_COMPANY')

      const currentUser = store.getters['auth/authData']?.user

      await router.push({
        name:
          currentUser?.defaultDashboard &&
          router.hasRoute(`dashboard-${currentUser?.defaultDashboard}`)
            ? `dashboard-${currentUser?.defaultDashboard}`
            : 'dashboard',
        params: { idCompany: currentCompany._id },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const logout = (isForced = false) => {
    store.dispatch('auth/logout', isForced).then(() => {
      googleLogout()
      clearStorage()
      clearAuthMessage()
    })
  }

  const clearAuthMessage = () => {
    store.dispatch('clearMessage')
  }

  return {
    isLoggingIn,
    isLoggedIn,
    currentCompany,
    authMessage,
    login,
    logout,
    clearAuthMessage,
  }
}
