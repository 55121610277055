import { protectedRequest } from '../boot/axios'
import BaseService from './BaseService'

class TagService extends BaseService {
  constructor() {
    super('tags')
  }

  // other implementation goes here

  searchTag(types, query, company) {
    return protectedRequest({
      method: 'GET',
      url: this._apiUrl + '/search',
      params: { q: query, types, company },
    })
  }
}

export default new TagService()
