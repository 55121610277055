import { networkMutationsCreator } from '../../../common/utils/creator.utils.js'

export const authLogin = networkMutationsCreator('AUTH_LOGIN')

export const removeMessage = 'AUTH_REMOVE_MESSAGE'

export const updateAuthData = 'AUTH_UPDATE_DATA'

export const logout = 'AUTH_LOGOUT'

export const googleAuth = networkMutationsCreator('AUTH_GOOGLE')

export const setIsForcedLogout = 'SET_IS_FORCED_LOGOUT'

export const refreshToken = networkMutationsCreator('REFRESH_TOKEN')

export const setCurrentCompany = 'SET_CURRENT_COMPANY'
