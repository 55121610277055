<template>
  <Sidebar
    v-model:visible="d_expanded"
    :base-z-index="1000"
    :class="isRTL ? 'pl-0 pr-3' : 'pl-3 pr-0'"
    :position="isRTL ? 'left' : 'right'"
    :show-close-icon="false"
    class="layout-rightmenu p-sidebar-sm fs-small py-3"
    @click="$emit('content-click', $event)"
    @hide="$emit('hide', d_expanded)"
  >
    <div
      :class="{ 'ml-3': !isRTL, 'mr-3': isRTL }"
      class="online-members flex flex-column mt-3"
    >
      <h6 class="header">ONLINE MEMBERS</h6>
      <div class="flex flex-row flex-wrap">
        <img alt="avatar-1" class="m-1" src="demo/images/avatar/avatar-1.png" />
        <img alt="avatar-2" class="m-1" src="demo/images/avatar/avatar-2.png" />
        <img alt="avatar-3" class="m-1" src="demo/images/avatar/avatar-3.png" />
        <img alt="avatar-4" class="m-1" src="demo/images/avatar/avatar-4.png" />
        <img alt="avatar-5" class="m-1" src="demo/images/avatar/avatar-5.png" />
        <img alt="avatar-6" class="m-1" src="demo/images/avatar/avatar-6.png" />
        <img alt="avatar-7" class="m-1" src="demo/images/avatar/avatar-7.png" />
        <img alt="avatar-8" class="m-1" src="demo/images/avatar/avatar-8.png" />
        <img alt="avatar-9" class="m-1" src="demo/images/avatar/avatar-9.png" />
        <img
          alt="avatar-10"
          class="m-1"
          src="demo/images/avatar/avatar-10.png"
        />
        <img
          alt="avatar-11"
          class="m-1"
          src="demo/images/avatar/avatar-11.png"
        />
        <img
          alt="avatar-12"
          class="m-1"
          src="demo/images/avatar/avatar-12.png"
        />
        <img
          alt="avatar-13"
          class="m-1"
          src="demo/images/avatar/avatar-13.png"
        />
        <img
          alt="avatar-14"
          class="m-1"
          src="demo/images/avatar/avatar-14.png"
        />
        <img
          alt="avatar-15"
          class="m-1"
          src="demo/images/avatar/avatar-15.png"
        />
        <img
          alt="avatar-16"
          class="m-1"
          src="demo/images/avatar/avatar-16.png"
        />
      </div>
      <span class="mt-3"><b>+19</b> Costumers</span>
    </div>
    <div
      :class="{ 'ml-3': !isRTL, 'mr-3': isRTL }"
      class="latest-activity flex flex-column mt-6"
    >
      <h6 class="header">LATEST ACTIVITY</h6>
      <div class="flex flex-row pt-2">
        <i
          :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"
          class="pi pi-images align-self-start p-2"
        ></i>
        <div class="flex flex-column">
          <span class="font-bold mb-1">New Sale</span>
          <span class="mb-2"
            >Richard Jones has purchased a blue t-shirt for $79.</span
          >
          <span class="flex align-items-center">
            <img
              :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"
              alt=""
              src="demo/images/avatar/activity-1.png"
            />
            <small class="muted-text">Emmy Adams, 21.40</small>
          </span>
        </div>
      </div>
      <div class="flex flex-row pt-3">
        <i
          :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"
          class="pi pi-images align-self-start p-2"
        ></i>
        <div class="flex flex-column">
          <span class="font-bold mb-1">Withdrawal Initiated</span>
          <span class="mb-2"
            >Your request for withdrawal of $2500 has been initiated.</span
          >
          <span class="flex align-items-center">
            <img
              :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"
              alt="avatar-2"
              src="demo/images/avatar/activity-2.png"
            />
            <small class="muted-text">Emily Walter, 21.40</small>
          </span>
        </div>
      </div>
      <div class="flex flex-row pt-3">
        <i
          :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"
          class="pi pi-images align-self-start p-2"
        ></i>
        <div class="flex flex-column">
          <span class="font-bold mb-1">Question Received</span>
          <span class="mb-2"
            >Jane Davis has posted a new question about your product.</span
          >
          <span class="flex align-items-center">
            <img
              :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"
              alt="avatar-3"
              src="demo/images/avatar/activity-3.png"
            />
            <small class="muted-text">Jane Davis, 21.45</small>
          </span>
        </div>
      </div>
    </div>
    <div
      :class="{ 'ml-3': !isRTL, 'mr-3': isRTL }"
      class="next-events flex flex-column mt-6"
    >
      <h6 class="header">NEXT EVENTS</h6>
      <ul>
        <li>
          <i :class="{ 'mr-3': !isRTL, 'ml-3': isRTL }" class="pi pi-eye"></i
          >A/B Test
        </li>
        <li>
          <i :class="{ 'mr-3': !isRTL, 'ml-3': isRTL }" class="pi pi-video"></i
          >Video Shoot
        </li>
        <li>
          <i
            :class="{ 'mr-3': !isRTL, 'ml-3': isRTL }"
            class="pi pi-sitemap"
          ></i
          >Board Meeting
        </li>
        <li>
          <i
            :class="{ 'mr-3': !isRTL, 'ml-3': isRTL }"
            class="pi pi-compass"
          ></i
          >Q4 Planning
        </li>
        <li>
          <i
            :class="{ 'mr-3': !isRTL, 'ml-3': isRTL }"
            class="pi pi-palette"
          ></i
          >Design Training
        </li>
      </ul>
    </div>
  </Sidebar>
</template>

<script>
export default {
  name: 'AppRightPanel',

  props: {
    expanded: Boolean,
  },

  emits: ['content-click', 'hide'],

  data() {
    return {
      d_expanded: null,
    }
  },

  computed: {
    isRTL() {
      return this.$appState.RTL
    },
  },

  watch: {
    expanded(value) {
      this.d_expanded = value
    },
  },
}
</script>

<style scoped></style>
