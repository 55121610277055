<template>
  <ul v-if="items" role="menu">
    <template v-for="(item, i) of items">
      <li
        v-if="visible(item) && !item.separator"
        :key="item.label || i"
        :class="[
          {
            'layout-root-menuitem': root,
            'active-menuitem': activeIndex === i && !item.disabled,
          },
        ]"
        role="menuitem"
      >
        <div v-if="root">
          <span class="layout-menuitem-text">{{ item.label }}</span>
        </div>
        <router-link
          v-if="item.name"
          v-ripple
          :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
          :style="item.style"
          :target="item.target"
          :to="{ name: item.name, params: { idCompany: idCompany } }"
          active-class="active-route"
          exact
          @click="onMenuItemClick($event, item, i)"
          @mouseenter="onMenuItemMouseEnter(i)"
          @mouseleave="onMenuItemMouseLeave"
        >
          <i :class="['layout-menuitem-icon', item.icon]"></i>
          <span class="layout-menuitem-text">{{ item.label }}</span>
          <span
            v-if="item.badge && !root"
            :class="item.badgeStyleClass"
            :style="item.badgeStyle"
            class="p-badge p-component p-badge-no-gutter"
            >{{ item.badge }}</span
          >
          <i
            v-if="item.items"
            class="pi pi-fw pi-angle-down layout-submenu-toggler"
          ></i>
        </router-link>
        <a
          v-if="!item.name"
          v-ripple
          v-tooltip.hover="isSlim && root ? item.label : null"
          :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
          :href="item.url || '#'"
          :style="item.style"
          :target="item.target"
          @click="onMenuItemClick($event, item, i)"
          @mouseenter="onMenuItemMouseEnter(i)"
          @mouseleave="onMenuItemMouseLeave"
        >
          <i :class="['layout-menuitem-icon', item.icon]"></i>
          <span class="layout-menuitem-text">{{ item.label }}</span>
          <span
            v-if="item.badge && !root"
            :class="item.badgeStyleClass"
            class="p-badge p-component p-badge-no-gutter"
            >{{ item.badge }}</span
          >
          <i
            v-if="item.items"
            class="pi pi-fw pi-angle-down layout-submenu-toggler"
          ></i>
        </a>

        <transition name="layout-menu">
          <appsubmenu
            v-show="
              item.items &&
              (root &&
              (!isSlim() ||
                (isSlim() && (mobileMenuActive || activeIndex !== null)))
                ? true
                : activeIndex === i)
            "
            :items="visible(item) && item.items"
            :menu-active="menuActive"
            :menu-mode="menuMode"
            :parent-menu-item-active="activeIndex === i"
            @menuitem-click="$emit('menuitem-click', $event)"
          ></appsubmenu>
        </transition>
      </li>
      <li
        v-if="visible(item) && item.separator"
        :key="'separator' + i"
        :style="item.style"
        class="p-menu-separator"
        role="separator"
      ></li>
    </template>
  </ul>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { ABILITY_TOKEN } from '@casl/vue'
import EventBus from './event-bus'

export default {
  name: 'Appsubmenu',

  inject: {
    $ability: { from: ABILITY_TOKEN },
  },

  props: {
    items: Array,

    root: {
      type: Boolean,
      default: false,
    },

    menuActive: {
      type: Boolean,
      default: true,
    },

    parentMenuItemActive: {
      type: Boolean,
      default: false,
    },

    menuMode: String,
    mobileMenuActive: Boolean,
    isSlimOrHorItemClick: Boolean,
  },

  emits: ['menuitem-click', 'root-menuitem-click'],

  setup() {
    const store = useStore()
    const currentCompany = computed(() => {
      return store.getters['auth/currentCompany']
    })
    const idCompany = computed(() => currentCompany.value._id)

    return { idCompany }
  },

  data() {
    return {
      activeIndex: null,
      hoverMenuActive: false,
    }
  },

  mounted() {
    EventBus.on('reset-active-index', () => {
      if (this.isHorizontalOrSlim() && !this.isMobile()) {
        this.activeIndex = null
      }
    })
  },

  methods: {
    onMenuItemClick(event, item, index) {
      if (item.disabled) {
        event.preventDefault()

        return
      }

      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item })
        event.preventDefault()
      }

      if (item.items) {
        event.preventDefault()
      } else {
        if (this.isHorizontalOrSlim()) {
          this.hoverMenuActive = false
        }

        if (this.menuMode !== 'static') {
          const ink = this.getInk(event.currentTarget)

          if (ink) {
            this.removeClass(ink, 'p-ink-active')
          }
        }
      }

      if (this.root) {
        this.hoverMenuActive = !this.hoverMenuActive

        this.$emit('root-menuitem-click', {
          originalEvent: event,
          isSameIndex: index === this.activeIndex,
        })
      }

      if (item.items) {
        this.activeIndex = index === this.activeIndex ? null : index
      }

      this.$emit('menuitem-click', {
        originalEvent: event,
        item: item,
      })
    },

    onMenuItemMouseEnter(index) {
      if (this.isSlimOrHorItemClick) {
        this.hoverMenuActive = true
      }

      if (
        this.root &&
        this.hoverMenuActive &&
        this.isHorizontalOrSlim() &&
        !this.isMobile()
      ) {
        this.activeIndex = index
      }
    },

    onMenuItemMouseLeave() {
      this.hoverMenuActive = false
    },

    isHorizontalOrSlim() {
      return this.menuMode === 'horizontal' || this.menuMode === 'slim'
    },

    isMobile() {
      return window.innerWidth <= 640
    },

    visible(item) {
      // returns true or false if the item has some of its child visible or not
      if (item?.items) return item?.items?.some((child) => this.visible(child))

      if (typeof item.visible === 'string') {
        if (item.visible === 'COMPANY_SCOPED') {
          return !!this.currentCompany
        }

        return false
      } else {
        return typeof item.visible === 'function'
          ? item.visible(this.$can)
          : item.visible !== false
      }
    },

    getInk(el) {
      for (let child of el.children) {
        if (
          typeof child.className === 'string' &&
          child.className.indexOf('p-ink') !== -1
        ) {
          return child
        }
      }

      return null
    },

    removeClass(element, className) {
      if (element.classList) element.classList.remove(className)
      else
        element.className = element.className.replace(
          new RegExp(
            '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
            'gi',
          ),
          ' ',
        )
    },

    isSlim() {
      return this.menuMode === 'slim'
    },
  },
}
</script>

<style scoped></style>
