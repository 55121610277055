<template>
  <div
    :class="{ 'layout-inline-menu-active': active }"
    class="layout-inline-menu"
  >
    <a
      :class="menuMode === 'horizontal' ? 'p-3 lg:p-1 py-3' : 'p-3'"
      :style="{ 'min-width': menuMode === 'horizontal' ? '165px' : 0 }"
      class="layout-inline-menu-action flex flex-row align-items-center"
      @click="onClick($event)"
    >
      <user-avatar />
      <span :class="{ 'ml-2': !isRTL, 'mr-2': isRTL }" class="flex flex-column">
        <span class="font-bold">{{ fullName }}</span>
        <small>{{ role }}</small>
      </span>
      <i
        :class="{ 'ml-auto': !isRTL, 'mr-auto': isRTL }"
        class="layout-inline-menu-icon pi pi-angle-down"
      ></i>
    </a>
    <span class="layout-inline-menu-tooltip p-tooltip">
      <span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
      <span class="layout-inline-menu-tooltip-text p-tooltip-text"
        >Amy Elsner</span
      >
    </span>

    <transition
      :name="
        menuMode === 'horizontal' && !isMobile()
          ? 'layout-inline-menu-action-panel-horizontal'
          : 'layout-inline-menu-action-panel'
      "
    >
      <ul
        v-show="active"
        :class="[
          'layout-inline-menu-action-panel',
          {
            'layout-inline-menu-action-panel-horizontal':
              menuMode === 'horizontal' && !isMobile(),
          },
        ]"
      >
        <li class="layout-inline-menu-action-item">
          <a class="flex flex-row align-items-center" @click="logout()">
            <i class="pi pi-power-off pi-fw"></i>
            <span>Logout</span>
          </a>
          <span class="layout-inline-menu-tooltip p-tooltip">
            <span
              class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"
            ></span>
            <span class="layout-inline-menu-tooltip-text p-tooltip-text"
              >Logout</span
            >
          </span>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import UserAvatar from './components/ui/UserAvatar.vue'
import useAuthentication from './composables/useAuthentication'

export default {
  name: 'AppInlineMenu',

  components: {
    UserAvatar,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    menuMode: String,
    inlineMenuKey: String,
  },

  setup() {
    const { logout } = useAuthentication()

    return { logout }
  },

  computed: {
    isRTL() {
      return this.$appState.RTL
    },

    fullName() {
      return this.$store.getters['auth/userFullName']
    },

    role() {
      return this.$store.getters['auth/userRole']
    },
  },

  methods: {
    onClick(event) {
      this.$emit('change-inlinemenu', !this.active, this.inlineMenuKey)
      event.preventDefault()
    },

    isMobile() {
      return window.innerWidth <= 991
    },
  },
}
</script>

<style scoped></style>
