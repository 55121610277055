import { exportExcel } from './types'

export default {
  SET_LIST(state, { items, count }) {
    state.list = [...items]
    state.totalRecords = count
  },
  SET_TOTAL_FILTERED(state, { items }) {
    state.getTotalFiltered = [...items]
  },
  SET_TOTAL(state, { items }) {
    state.total = [...items]
  },
  ADD_TO_LIST(state, item) {
    state.list = [item, ...state.list]
    state.totalRecords = state.totalRecords + 1
  },
  UPDATE_LIST(state, item) {
    for (let list of state.list) {
      const updateIndex = list.items.findIndex((el) => el._id === item._id)

      if (updateIndex !== -1) {
        list.items[updateIndex].qtt_billed = item.qtt_billed
        list.items[updateIndex].validated3 = item.validated3
        list.items[updateIndex].billed = item.billed.amount
        list.items[updateIndex].closingBalance = item.closingBalance
        list.totalAmountBilled = list.items.reduce(
          (accum, item) => accum + item.billed,
          0,
        )
        state.list = [...state.list]
      }
    }
  },
  REMOVE_FROM_LIST(state, id) {
    state.list = state.list.filter((el) => el._id !== id)
  },
  [exportExcel.requesting](state) {
    state.isExporting = true
  },
  [exportExcel.success](state, { rootState }) {
    rootState.message = { text: 'Exported successfully', type: 'error' }
    state.isExporting = false
  },
  [exportExcel.failure](state, { rootState, payload }) {
    rootState.message = { text: payload.message, type: 'error' }
    state.isExporting = false
  },
  DATA_COUNTED_PER_STATE(state, items) {
    state.dataCountedPerState = [...items]
  },
}
