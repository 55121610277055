<template>
  <div
    class="layout-breadcrumb-container flex justify-content-between align-items-center shadow-1"
  >
    <Breadcrumb :home="home" :model="items" class="layout-breadcrumb pl-4 py-2">
      <template #item="{ label, item, props }">
        <router-link
          v-if="item.route ?? item.to"
          v-slot="routerProps"
          :to="item.route ?? item.to"
          custom
        >
          <a :href="routerProps.href" v-bind="props.action">
            <span v-bind="props.icon" />
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span v-if="item.icon" v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
        </a>
      </template>
    </Breadcrumb>
  </div>
</template>

<script>
import { computed, inject } from 'vue'

export default {
  components: {},

  setup() {
    const currentCompany = inject('currentCompany')
    const idCompany = computed(() => currentCompany.value._id)

    return { idCompany }
  },

  data() {
    return {
      home: {
        icon: 'pi pi-home',
        route: { name: 'dashboard', params: { idCompany: this.idCompany } },
      },
    }
  },

  computed: {
    items() {
      return this.$store.getters['breadcrumb/current']
    },
  },

  watch: {
    $route() {
      this.watchRouter()
    },
  },

  created() {
    this.watchRouter()
  },

  methods: {
    watchRouter() {
      if (this.$router.currentRoute.value.meta.breadcrumb) {
        this.$store.dispatch(
          'breadcrumb/setCurrent',
          this.$router.currentRoute.value.meta.breadcrumb,
        )
      }
    },
  },
}
</script>

<style scoped></style>
