<template>
  <SignIn v-if="$route.name === 'login'" />
  <PendingApproval v-else-if="$route.path === '/account/pending-approval'" />
  <Initialization v-else-if="$route.path === '/account/initialization'" />
  <Error v-else-if="$route.path === '/error'" />
  <Access v-else-if="$route.path === '/access'" />
  <NotFound v-else-if="$route.path === '/notfound'" />
  <App
    v-else-if="isLoggedIn"
    :layout-mode="layoutMode"
    :menu-theme="menuTheme"
    :topbar-theme="topbarTheme"
    @menu-theme="onMenuTheme"
    @topbar-theme="onTopbarThemeChange"
    @layout-mode-change="onLayoutChange"
  />
</template>

<script>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useAbility } from '@casl/vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import App from './App.vue'
import Error from './pages/Error'
import Access from './pages/Access'
import SignIn from './pages/SignIn'
import NotFound from './pages/NotFound'
import PendingApproval from './pages/PendingApproval.vue'
import useStorage from './composables/useStorage'
import Initialization from './pages/account/Initialization.vue'
import useAuthentication from './composables/useAuthentication'
import UserService from '@/service/UserService'

export default {
  components: {
    App: App,
    Error: Error,
    Access: Access,
    SignIn: SignIn,
    NotFound: NotFound,
    PendingApproval: PendingApproval,
    Initialization: Initialization,
  },

  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('clearMessage')
    next()
  },

  setup() {
    const $ability = useAbility()
    const store = useStore()
    const router = useRouter()
    const { logout } = useAuthentication()
    const { t } = useI18n()

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn'])

    const authData = computed(() => {
      return store.getters['auth/authData']
    })

    const isForcedLogout = computed(() => {
      return store.getters['auth/isForcedLogout']
    })

    const { getStorageItem, setStorageItem, removeStorageItem } = useStorage()

    onBeforeMount(() => {
      const storageAuthData = getStorageItem('AUTH_DATA')
      const selectedCompany = getStorageItem('CURRENT_COMPANY')

      if (storageAuthData) {
        store.dispatch('auth/updateAuthData', {
          authData: storageAuthData,
          currentCompany: selectedCompany,
        })
        store.dispatch('auth/setCurrentCompany', selectedCompany)
      }
    })

    const removeAuthStorage = () => {
      removeStorageItem('AUTH_DATA')
      removeStorageItem('CURRENT_COMPANY')
    }

    const setAuthStorage = () => {
      if (!authData.value.user.ability_rules?.length) {
        logout(true)
        removeAuthStorage()
        router.push({
          name: 'login',
          params: {
            message: 'No access right have been defined for your account',
          },
        })

        return
      }

      setStorageItem('AUTH_DATA', authData.value)

      const current_company = authData.value.user.selectedCompany
        ? authData.value.user.selectedCompany
        : authData.value.user.companies[0]

      if (!authData.value.user.selectedCompany) {
        UserService.changeCompany(authData.value.user.companies[0]._id)
      }
      setStorageItem('CURRENT_COMPANY', current_company)
      $ability.update(authData.value.user.ability_rules)
    }

    router.beforeEach(function (to, from) {
      if (to.name !== from.name) {
        store.dispatch('clearMessage')
      }
    })

    return {
      isLoggedIn,
      isForcedLogout,
      removeAuthStorage,
      setAuthStorage,
      // vuelidate,
      t,
    }
  },

  data() {
    return {
      theme: 'bluegrey',
      layoutMode: 'light',
      topbarTheme: 'orange',
      menuTheme: 'dark',
      scale: 12,
    }
  },

  watch: {
    async isLoggedIn(value) {
      if (value) {
        this.setAuthStorage()
      } else {
        this.removeAuthStorage()

        if (this.isForcedLogout) {
          this.$store.dispatch('auth/setIsForcedLogout', false)

          return
        }

        this.$router.push({ name: 'login' })
      }
    },
  },

  mounted() {
    this.applyScale()
  },

  methods: {
    onLayoutChange(layout) {
      this.layoutMode = layout
      this.$appState.layoutMode = layout
      this.menuTheme = layout

      const logo = document.getElementById('logo')
      logo.src = 'layout/images/logo-light.svg'

      if (layout === 'dark') {
        this.topbarTheme = 'dark'
      } else {
        this.topbarTheme = 'blue'
      }
    },

    onTopbarThemeChange(theme) {
      this.topbarTheme = theme.name

      const themeName = theme.name
      const logo = document.getElementById('logo')

      if (
        themeName == 'white' ||
        themeName == 'yellow' ||
        themeName == 'amber' ||
        themeName == 'orange' ||
        themeName == 'lime'
      ) {
        logo.src = 'layout/images/logo-dark.svg'
      } else {
        logo.src = 'layout/images/logo-light.svg'
      }
    },

    onMenuTheme(menuTheme) {
      this.menuTheme = menuTheme.name
    },

    applyScale() {
      document.documentElement.style.fontSize = this.scale + 'px'
    },
  },
}
</script>

<style scoped></style>
