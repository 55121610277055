import { protectedRequest, publicRequest } from '../boot/axios'

class AuthService {
  constructor() {
    this._apiUrl = '/auth'
  }

  login(credentials) {
    return publicRequest({
      method: 'POST',
      url: this._apiUrl + '/login',
      data: credentials,
    })
  }

  refreshToken(accessToken, refreshToken) {
    return protectedRequest(
      {
        method: 'POST',
        url: this._apiUrl + '/refreshToken',
        data: {
          refreshToken,
        },
      },
      accessToken,
    )
  }
}

export default new AuthService()
