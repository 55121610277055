import { protectedRequest } from '../boot/axios'

class ConnectorService {
  constructor() {
    this._apiUrl = '/connectors'
  }

  getAll() {
    return protectedRequest({
      method: 'GET',
      url: this._apiUrl + '/',
    })
  }

  getByCompany(companyId) {
    return protectedRequest({
      method: 'GET',
      url: this._apiUrl + '/company/' + companyId,
    })
  }
}

export default new ConnectorService()
