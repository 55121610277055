import ProductionService from '../../../service/ProductionService'

export const getAll = async ({ commit }) => {
  const { data } = await ProductionService.getAll()
  commit(`SET_LIST`, data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await ProductionService.paginatedProductionLocalClient(query)
  commit(`SET_LIST`, data)

  return data
}

export const getTotal = async ({ commit }, query) => {
  const { data } = await ProductionService.totalProductionLocalClient(query)
  commit('SET_TOTAL', data)

  return data
}
